/* Add this CSS to your Footer.css file */
.footer-container {
  background-color: #242424;
  color: #fff;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Center-align text content */
}

.footer-heading {
  display: flex;
  justify-content: center;
  width: 350px;
  margin: auto;
  margin-bottom: 20px;
  font-size: 1.5rem;
  justify-items: center;
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(0.25turn, #ff67ff, #3fb3ff);
  border-image-slice: 1;
  font-size: 2.5rem;
  padding-bottom: 20px;
}
/* Center align the button text */
.footer-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
}

/* Center the button with the inputs */
.input-areas {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Center the inputs */
.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
  width: 100%; /* Make the inputs responsive */
  max-width: 350px; /* Limit the maximum width for larger screens */
}

.footer-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add responsive styling for smaller screens */
@media screen and (max-width: 820px) {
  .footer-input {
    width: 100%;
    width: 350px; /* Remove the maximum width for smaller screens */
  }

  .social-media-wrap {
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
  }
}

/* Social Icons */

.fa-explosion {
  font-size: 2rem;
  margin: 10px;
  background-image: linear-gradient(#ff67ff, #3fb3ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.social-icons i {
  display: flex;
  font-size: 4;
  padding: 10px;
  justify-content: center;
  align-items: center;
  margin: 5px;
  background-image: linear-gradient(#ff67ff, #3fb3ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

sub {
  display: flex;
  align-content: baseline;
}

.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000x;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.website-rights {
  color: #fff;
  opacity: 70%;
  margin: 10px;
}

.footer-banner {
  margin-top: 20px;
  font-size: 1.2rem;
  max-width: 490px;
}
/* Additional styles for other elements can be added here */
