/* Adjust the arrow spacing */
.testimonial-info {
  padding-top: 100px;
  text-align: center;
}
/* Increase the arrow size */

/* Apply gradient to arrows */
.arrow-prev {
  background-image: linear-gradient(#ff67ff, #3fb3ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.arrow-next {
  background-image: linear-gradient(#ff67ff, #3fb3ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Style the arrow shape and color */
.carousel-arrows {
  border-radius: 50%;
  color: white;
  line-height: 40px;
  cursor: pointer;
  margin-top: -50px;
}

.arrow-next {
  font-size: 40px;
  font-weight: 1000;
  cursor: pointer;
  margin: 10px 80px;
}
.arrow-prev {
  font-size: 40px;
  font-weight: 1000;
  cursor: pointer;
  margin: 10px 80px; /* Adjust vertical spacing as needed */
}

.testimonial-container {
  padding-bottom: 20px;
}

.testimonial-page {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  color: fff;
  background-color: #fff;
}

.testimonial-page h1 {
  margin: auto;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(0.25turn, #ff67ff, #3fb3ff);
  border-image-slice: 1;
  font-size: 2.5rem;
}

.carousel-image {
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-stars {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.carousel-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 300px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 0 auto;
  margin-top: 140px;
}

.star {
  color: gold;
}
