video {
  object-fit: cover;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 4rem;
  margin-top: 400px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  text-align: center;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  text-align: center;
}

.hero-btns {
  margin: 32px;
  flex-direction: column;
}

.hero-btns .btn {
  margin: 6px;
  width: 100%;
  flex-direction: row;
  font-size: 1.5rem;
}

.fa-arrow-right {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 4rem;

    .btn-mobile {
      display: flex;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: 300px;
  }

  .hero-container > p {
    font-size: 30px;
    max-width: 400px;
  }

  .btn {
    width: 100%;
  }
}

/* Hide the default video controls */
video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
video::-webkit-media-controls-play-button {
  display: none !important;
}
video::-webkit-media-controls-enclosure {
  overflow: hidden !important;
}

.video-element::-webkit-media-controls {
  display: none !important;
}

.video-element::-webkit-media-controls-enclosure {
  display: none !important;
}

.video-element::-webkit-media-controls-overlay-enclosure {
  display: none !important;
}

.fa-clipboard {
  margin: 10px 10px;
  font-size: 1.5rem;
}

.fa-arrow-right {
  font-size: 1.5rem;
  margin: 10px 10px;
}
