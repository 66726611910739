.about-section {
  background-color: #fff;
}

.about-image {
  flex: 0 0 200px;
  position: relative;
  text-align: center;
}

.about-text {
  display: flex;
  flex: 1;
  padding: 0 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  flex-direction: column;
  text-align: center;
}

.about-text p {
  margin: 10px;
  text-align: left;
  font-size: 1rem;
}

.about-text h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  align-items: center;
  padding: 8px 20px;
}

.about-text h2 {
  font-size: 2rem;
  font-weight: bold;
  padding: 8px 20px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(0.25turn, #ff67ff, #3fb3ff);
  border-image-slice: 1;
}

.image-border {
  width: 300px;
  height: 300px;
  border: 5px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  margin-top: 20px;
}

.circle-image {
  width: 100%;
  height: 100%;
  object-fit: 70%;
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  padding: 50px 20px;
  max-width: 900px;
  margin: 0 auto;
  justify-content: center;
}

.colored-section {
  background-color: #dbdbdb;
  width: 100%;
}

.call-to-action {
  text-align: center;
  margin-bottom: 40px;
}

.call-to-action h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 20px;
}

.call-to-action p {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.call-to-action button {
  padding: 8px 50px;
  background-color: #337ab7;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1.5em;
  cursor: pointer;
}

.faq-section {
  padding: 50px 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-right: 20px;
}

.faq-section h2 {
  text-align: center;
  display: flex;
  justify-content: center;

  font-size: 2.5rem;
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(0.25turn, #ff67ff, #3fb3ff);
  border-image-slice: 1;
  margin: auto;
  margin-bottom: 20px;
  padding: 8px 20px;
}

.faq-question {
  font-size: 1.3rem;
  padding: 8px 20px;
}

.faq-answer {
  margin-top: 15px;
  padding: 8px 20px;
  max-width: 480px;
  font-size: 1.2rem;
}
