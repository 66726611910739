:root {
  --primary: #fff;
  --secondary: #111;
}

.btn:visited {
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.btn-mobile {
  align-items: center;
  justify-content: center;
}

a {
  display: flex;
  justify-content: center;
}
.btn {
  padding: 8px 20px;
  border-radius: 10px;
  outline: none;
  border: none;
  display: block;
}

.btn--primary {
  background-color: var(--primary);
  color: var(--secondary);
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: var(--primary);
  padding: 8px 20px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--gradient-outline {
  background-color: transparent;
  border: 2px solid transparent; /* You can adjust the border width as needed */
  border-image: linear-gradient(#ff67ff, #3fb3ff);
  border-image-slice: 1; /* Ensures the whole border is filled with the gradient */
  color: white;
  padding: 10px 20px; /* Adjust padding as needed */
  text-align: center;
  text-decoration: none; /* Remove underlines for links */
  cursor: pointer;
}

.btn--gradient-primary {
  color: var(--primary);
  background-color: transparent;
  width: 200px;

  padding: 8px 20px;
  align-items: center;
}

.btn--gradient-primary:hover {
  background-image: linear-gradient(#ff67ff, #3fb3ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn--outline-dark {
  background-color: var(--secondary);
  color: var(--primary);
  padding: 8px 20px;
  border: 1px solid var(--secondary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 1.5rem;
  text-decoration: none;
}

.btn--large {
  padding: 8px 26px;
  font-size: 2rem;
  text-decoration: none;
}
