ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

body {
  background-color: #f8fafe;
}

#type {
  text-align: center;
  color: rgb(49, 113, 218);
}

.container-1200 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.price__card__container {
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  padding: 50px 20px;
  max-width: 900px;
  margin: 0 auto;
  justify-content: center;
}

.price__card__item {
  background-color: #fff;
  flex: 1;
  margin: 0 15px 30px;
  max-width: 350px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  transition: all 0.3s ease-out;
  min-width: 300px;
}

.price__card__item--title {
  padding: 20px;
  border-radius: 2px;
  color: #f8fafe;
  margin: 10px;
  text-align: center;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  font-size: 30px;
}

.price__card__item--pricing {
  padding: 25px;
  align-content: left;
  padding-bottom: 5px;
}

.price__card__item--pricing h1 {
  padding: 10px 0;
  font-size: 65px;
  text-align: center;
}

.price__card__item--pricing h2 {
  margin-top: 5px;
  font-size: 20px;
  text-align: left;
  padding-bottom: 0px;
}
.price__card__item--pricing h3 {
  margin-top: -15px;
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;
  padding-bottom: 0px;
}

.price__card__item--pricing p {
  text-align: left;
  justify-content: center;
  font-size: 1rem;
  margin-top: 5px;
}

.price__card__item--pricing p:nth-child(2) {
  text-transform: uppercase;
}

.price__card__item--btn-container {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.price__card__item--btn-container button {
  min-width: 300px;
}

.price-card__item--features {
  margin: 10px;
  padding-top: 0;
}

.price__card__item--features > ul {
  display: flex; /* change padding to display */
  align-items: top; /* add align-items */
  text-align: left;
  flex-direction: column;
  padding: 0; /* adjust padding */
  margin: 30px;
  margin-top: 10px;
}

.price__card__item--features > ul li {
  padding: 7.5px 0px;
  display: flex; /* add display */
  align-items: center; /* add align-items */
  font-size: 1rem;
  flex-direction: row;
}

.blueGradient {
  background: linear-gradient(66deg, #caedff 0%, #6ec9fa 100%);
}

.purpleGradient {
  background: linear-gradient(66deg, #daceff 0%, #9f94fe 100%);
}

.orangeGradient {
  background: linear-gradient(66deg, #ffdcce 0%, #f79151 100%);
}
.greenGradient {
  background: linear-gradient(66deg, #a7e98f 0%, #2ed573 100%);
}
.redGradient {
  background: linear-gradient(66deg, #e08585 0%, #e43c3c 100%);
}

.price__card__item--slider-sessions {
  display: flex;
  width: 80%;
  margin: 0 30px;
}

.price__card__item--slider-labels {
  font-size: 1.2rem;

  display: flex;
  flex-direction: row;
  margin: 0 30px;
  transform: translate(4px, 0px);
}

.price__card__item--slider-sessions p {
  font-size: 16px;
  margin-bottom: 10px;
}

.price__card__item--slider-sessions input[type='range'] {
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.price__card__item--slider-sessions input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #4d4d4d;
  cursor: pointer;
  border-radius: 50%;
}

.price__card__item--slider-sessions input[type='range']::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #4d4d4d;
  cursor: pointer;
  border-radius: 50%;
}

.price__card__item--slider-sessions input[type='range']:focus {
  outline: none;
}

.price__card__item--slider-sessions
  input[type='range']::-webkit-slider-thumb:hover,
.price__card__item--slider-sessions
  input[type='range']::-moz-range-thumb:hover {
  background: #333333;
}

.price__card__toggle-container {
  text-align: center;
  margin-bottom: 20px;
  font-size: 30px;
  margin-top: 30px;
  font-weight: 600 d;
}

.price__card__toggle-buttons {
  display: inline-block;
}

.price__card__toggle-button {
  display: inline-block;
  padding: 10px 40px;
  margin: 5px 20px;
  font-size: 24px;
  font-weight: 400;
  border: 3px solid #242424;
  border-radius: 10px;
  color: #333;
  background-color: transparent;
  cursor: pointer;
  padding: 10px 10px;
  transition: all 0.3s ease-out;
  align-items: center;
}

.price__card__toggle-button:hover {
  border: 3px solid #242424;
  font-weight: 400;
  background-color: #242424;
  color: #fff;
  padding: 10px 10px;
}
.price__card__toggle-button.active {
  border: 3px solid #242424;
  font-weight: 400;
  background-color: #242424;
  color: #fff;
  padding: 10px 10px;
  border-radius: 10px;
}

.fa-check {
  font-size: 1.2rem;
  margin-right: 15px;
}

.fa-stopwatch {
  font-size: 1.2rem;
  margin-right: 15px;
}

.fa-bowl-rice {
  font-size: 1.2rem;
  margin-right: 15px;
}

.fa-dumbbell {
  font-size: 1.1rem;
  margin-right: 13px;
}
