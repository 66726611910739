nav_button:hover {
  background-color: linear-gradient(90deg, #ff67ff, #3fb3ff);
  color: #fff;
  transition: 250ms;
}

.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: flex-start;
  margin-left: 5x;
  margin-top: -5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar i {
  margin: 5px;
  background-image: linear-gradient(#ff67ff, #3fb3ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fa-explosion {
  margin-left: 10px;
  font-size: 2.1rem;
  display: flex;
  align-items: center;
  padding: 1px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end; /* change to end to move the nav to the right */
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(0.25turn, #ff67ff, #3fb3ff);
  border-image-slice: 1;
  width: 100%;
  transition: all 0.2s ease-out;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: flex-start;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    font-size: 1.75rem;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #c1c1c1;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10%, 60%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-110%, 70%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2.5rem;
    translate: 20px -15px;
    padding: 1px;
  }

  .fa-bars {
    color: #fff;
    font-size: 2.1rem;
    translate: 20px -10px;
    padding: 1px;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    width: 80%;
    background-color: transparent;
    text-decoration: none;
    font-size: 1.75rem;
    color: #fff;
    padding: 14px 20px;
    transition: all 0.3s ease-out;
    background-color: transparent;
    border: 2px solid transparent; /* You can adjust the border width as needed */
    border-image: linear-gradient(#ff67ff, #3fb3ff);
    border-image-slice: 1; /* Ensures the whole border is filled with the gradient */
    color: white;
    padding: 10px 20px; /* Adjust padding as needed */
    text-align: center;
    text-decoration: none; /* Remove underlines for links */
    cursor: pointer;
    border-radius: 10px;
  }

  .nav-links-mobile:hover {
    background: #ffffff;
    color: #242424;
    transition: 250ms;
  }
}

@media screen and (maxwidth: 969px) {
  .hero-container {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (maxwidth: 760px) {
  .hero-container {
    font-size: 50px;
    margin-top: -100px;
  }
}
